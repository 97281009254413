<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button"
						class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/Dtu/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">DTU編號</label>
							<div class="col-sm-10">
								<input v-model="model.id" type="text" class="form-control" id="id" name="id" readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="dtu_sn" class="col-sm-2 col-form-label">DTU型號</label>
							<div class="col-sm-10">
								<select v-model="model.dtu_sn" class="form-control" id="dtu_sn"
											name="dtu_sn">
											<option value="TAS-L15">TAS-L15</option>
											<option value="T310(分享器)">T310(分享器)</option>											
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場代號</label>
							<div class="col-sm-10">
								<input v-model="model.housecode" type="text" class="form-control" id="housecode"
									name="housecode" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="dtu_brate" class="col-sm-2 col-form-label">串埠速率</label>
							<div class="col-sm-10">
								<input v-model="model.dtu_brate" type="text" class="form-control" id="dtu_brate"
									name="dtu_brate" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="cntrl" class="col-sm-2 col-form-label">MQTT開關</label>
							<div class="col-sm-10">
								<select v-model="model.cntrl" class="form-control" id="cntrl" name="cntrl">
									<option value="1">開啟</option>
									<option value="0">關閉</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="dtu_ip" class="col-sm-2 col-form-label">MQTT連線狀態</label>
							<div class="col-sm-10">
								<input v-model="model.dtu_ip" type="text" class="form-control" id="dtu_ip"
									name="dtu_ip" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="tttdtu_id" class="col-sm-2 col-form-label">DTU編號</label>
							<div class="col-sm-10">
								<input v-model="model.tttdtu_id" type="text" class="form-control" id="tttdtu_id"
									name="tttdtu_id" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="simid" class="col-sm-2 col-form-label">SIM編號</label>
							<div class="col-sm-10">
								<input v-model="model.simid" type="text" class="form-control" id="simid" name="simid" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="owner" class="col-sm-2 col-form-label">門號擁有人</label>
							<div class="col-sm-10">
								<input v-model="model.owner" type="text" class="form-control" id="owner" name="owner" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="iptime" class="col-sm-2 col-form-label">更新時間</label>
							<div class="col-sm-10">
								<input v-model="model.iptime" type="text" class="form-control" id="iptime"
									name="iptime" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="dt1" class="col-sm-2 col-form-label">建立時間</label>
							<div class="col-sm-10">
								<input v-model="model.dt1" type="text" class="form-control" id="dt1" name="dt1" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<input v-model="model.note" type="text" class="form-control" id="note" name="note" />
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue';
import { getDtu, postDtu } from '@/api/Dtu.js'

export default {
	name: 'DtuEdit',
	components: {
		LayoutDiv,
	},
	data() {
		return {
			model: {
				id: '',
				dtu_sn: '',
				housecode: '',
				dtu_brate: '',
				cntrl: '',
				dtu_ip: '',
				tttdtu_id: '',
				simid: '',
				owner: '',
				iptime: '',
				dt1: '',
				note: '',

			},
			isSaving: false,
		};
	},
	created() {
		const id = this.$route.params.id;

		getDtu(id).then(rep => {
			this.model = rep.data;
		});

	},
	methods: {
		handleSave() {
			if (this.isSaving == true) return;

			this.isSaving = true;
			postDtu(this.model).then(rep => {

				this.isSaving = false;
				return rep;
			})
				.catch(error => {
					this.isSaving = false;
					return error
				});
		},
	},
};
</script>